// Select2
(function( $ ) {

    'use strict';

    if ( $.isFunction($.fn[ 'select2' ]) ) {

        $(function() {
            $('[data-plugin-selectTwo]').each(function() {
                var $this = $( this ),
                    opts = {};

                var pluginOptions = $this.data('plugin-options');
                if (pluginOptions)
                    opts = pluginOptions;

                $this.themePluginSelect2(opts);
            });
        });

    }

}).apply(this, [ jQuery ]);

(function(theme, $) {

    theme = theme || {};

    var instanceName = '__select2';

    var PluginSelect2 = function($el, opts) {
        return this.initialize($el, opts);
    };

    PluginSelect2.defaults = {
    };

    PluginSelect2.prototype = {
        initialize: function($el, opts) {
            if ( $el.data( instanceName ) ) {
                return this;
            }

            this.$el = $el;

            this
                .setData()
                .setOptions(opts)
                .build();

            return this;
        },

        setData: function() {
            this.$el.data(instanceName, this);

            return this;
        },

        setOptions: function(opts) {
            this.options = $.extend( true, {}, PluginSelect2.defaults, opts );

            return this;
        },

        build: function() {
            this.$el.select2( this.options );

            return this;
        }
    };

    // expose to scope
    $.extend(theme, {
        PluginSelect2: PluginSelect2
    });

    // jquery plugin
    $.fn.themePluginSelect2 = function(opts) {
        return this.each(function() {
            var $this = $(this);

            if ($this.data(instanceName)) {
                return $this.data(instanceName);
            } else {
                return new PluginSelect2($this, opts);
            }

        });
    }

}).apply(this, [ window.theme, jQuery ]);


// Colorpicker
(function( $ ) {

    'use strict';

    if ( $.isFunction($.fn[ 'colorpicker' ]) ) {

        $(function() {
            $('[data-plugin-colorpicker]').each(function() {
                var $this = $( this ),
                    opts = {};

                var pluginOptions = $this.data('plugin-options');
                if (pluginOptions)
                    opts = pluginOptions;

                $this.themePluginColorPicker(opts);
            });
        });

    }

}).apply(this, [ jQuery ]);

// Colorpicker
(function(theme, $) {

    theme = theme || {};

    var instanceName = '__colorpicker';

    var PluginColorPicker = function($el, opts) {
        return this.initialize($el, opts);
    };

    PluginColorPicker.defaults = {
    };

    PluginColorPicker.prototype = {
        initialize: function($el, opts) {
            if ( $el.data( instanceName ) ) {
                return this;
            }

            this.$el = $el;

            this
                .setData()
                .setOptions(opts)
                .build();

            return this;
        },

        setData: function() {
            this.$el.data(instanceName, this);

            return this;
        },

        setOptions: function(opts) {
            this.options = $.extend( true, {}, PluginColorPicker.defaults, opts );

            return this;
        },

        build: function() {
            this.$el.colorpicker( this.options );

            return this;
        }
    };

    // expose to scope
    $.extend(theme, {
        PluginColorPicker: PluginColorPicker
    });

    // jquery plugin
    $.fn.themePluginColorPicker = function(opts) {
        return this.each(function() {
            var $this = $(this);

            if ($this.data(instanceName)) {
                return $this.data(instanceName);
            } else {
                return new PluginColorPicker($this, opts);
            }

        });
    }

}).apply(this, [ window.theme, jQuery ]);


// Datepicker
(function( $ ) {

    'use strict';

    if ( $.isFunction($.fn[ 'datepicker' ]) ) {

        $(function() {
            $('[data-plugin-datepicker]').each(function() {
                var $this = $( this ),
                    opts = {};

                var pluginOptions = $this.data('plugin-options');
                if (pluginOptions)
                    opts = pluginOptions;

                $this.themePluginDatePicker(opts);
            });
        });

    }

}).apply(this, [ jQuery ]);

(function(theme, $) {

    theme = theme || {};

    var instanceName = '__datepicker';

    var PluginDatePicker = function($el, opts) {
        return this.initialize($el, opts);
    };

    PluginDatePicker.defaults = {
    };

    PluginDatePicker.prototype = {
        initialize: function($el, opts) {
            if ( $el.data( instanceName ) ) {
                return this;
            }

            this.$el = $el;

            this
                .setVars()
                .setData()
                .setOptions(opts)
                .build();

            return this;
        },

        setVars: function() {
            this.skin = this.$el.data( 'plugin-skin' );

            return this;
        },

        setData: function() {
            this.$el.data(instanceName, this);

            return this;
        },

        setOptions: function(opts) {
            this.options = $.extend( true, {}, PluginDatePicker.defaults, opts );

            return this;
        },

        build: function() {
            this.$el.datepicker( this.options );

            if ( !!this.skin ) {
                this.$el.data('datepicker').picker.addClass( 'datepicker-' + this.skin );
            }

            return this;
        }
    };

    // expose to scope
    $.extend(theme, {
        PluginDatePicker: PluginDatePicker
    });

    // jquery plugin
    $.fn.themePluginDatePicker = function(opts) {
        return this.each(function() {
            var $this = $(this);

            if ($this.data(instanceName)) {
                return $this.data(instanceName);
            } else {
                return new PluginDatePicker($this, opts);
            }

        });
    }

}).apply(this, [ window.theme, jQuery ]);

$.fn.datepicker.dates['ru'] = {
    days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота", "Воскресенье"],
    daysShort: ["Вск", "Пнд", "Втр", "Срд", "Чтв", "Птн", "Суб", "Вск"],
    daysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    today: "Сегодня",
    clear: "Очистить",
    format: "dd.mm.yyyy",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 1
};



// TimePicker
(function( $ ) {

    'use strict';

    if ( $.isFunction($.fn[ 'timepicker' ]) ) {

        $(function() {
            $('[data-plugin-timepicker]').each(function() {
                var $this = $( this ),
                    opts = {};

                var pluginOptions = $this.data('plugin-options');
                if (pluginOptions)
                    opts = pluginOptions;

                $this.themePluginTimePicker(opts);
            });
        });

    }

}).apply(this, [ jQuery ]);

(function(theme, $) {

    theme = theme || {};

    var instanceName = '__timepicker';

    var PluginTimePicker = function($el, opts) {
        return this.initialize($el, opts);
    };

    PluginTimePicker.defaults = {
        disableMousewheel: true
    };

    PluginTimePicker.prototype = {
        initialize: function($el, opts) {
            if ( $el.data( instanceName ) ) {
                return this;
            }

            this.$el = $el;

            this
                .setData()
                .setOptions(opts)
                .build();

            return this;
        },

        setData: function() {
            this.$el.data(instanceName, this);

            return this;
        },

        setOptions: function(opts) {
            this.options = $.extend( true, {}, PluginTimePicker.defaults, opts );

            return this;
        },

        build: function() {
            this.$el.timepicker( this.options );

            return this;
        }
    };

    // expose to scope
    $.extend(theme, {
        PluginTimePicker: PluginTimePicker
    });

    // jquery plugin
    $.fn.themePluginTimePicker = function(opts) {
        return this.each(function() {
            var $this = $(this);

            if ($this.data(instanceName)) {
                return $this.data(instanceName);
            } else {
                return new PluginTimePicker($this, opts);
            }

        });
    }

}).apply(this, [ window.theme, jQuery ]);


