(function ($) {
  var TOKEN = $('meta[name="csrf-token"]').attr('content');
  var TOKEN_URL = $('meta[name="csrf-token-url"]').attr('content');
  var ID_REPLACER = '7777777';
  var MESSAGES = $.parseJSON($('meta[name="js-lang-messages"]').attr('content'));
  var configsUrl = $('meta[name="configs-url"]').attr('content');
  var configs;

  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': TOKEN
    }
  });

  $(document).ajaxError(function (event, jqxhr, settings, thrownError) {
    $.ajax({
      type: 'GET',
      url: TOKEN_URL,
      success: function (response) {
        TOKEN = response;

        $.ajaxSetup({
          headers: {
            'X-CSRF-TOKEN': TOKEN
          }
        });
      }
    });
  });


  swal.setDefaults({cancelButtonText: MESSAGES.cancelButtonText});


  function getConfigs() {
    return $.ajax({
      type: 'GET',
      url: configsUrl,
      success: function (response) {
        configs = response;
      }
    });
  }


  function initDropzone() {
    Dropzone.autoDiscover = false;
    var $dz = $(".dropzone");
    $dz.dropzone({
      url: $dz.data('action'),
      headers: {"X-CSRF-TOKEN": TOKEN},
      maxFiles: 100,
      success: function (file, response) {
        file.previewElement.classList.add("dz-success");

        if ($dz.data('append-output')) {
          var $input;
          $input = $($dz.data('append-output'));

          if ($input.length) {
            var value = $input.val();

            if (value.length) {
              value = JSON.parse(value);
              if (!(value instanceof Array)) {
                value = [].push(value);
              }
            }
            value.push(response);
            $input.val(JSON.stringify(value));
          }
        }
      }
    });
  }


  function handleCategoryFields() {
    $(document).on('change', '.category[data-url]', loadCategoryFields);
    $(document).on('change', '.parent-select', handleMultiDropdown);
    $(document).on('change', '#has_positions', handleTenderPositions);
  }


  function handleTenderFilters() {
    var $category = $('#filter-category-id'),
      $perPageSelect = $('#pref-perpage'),
      $perPageField = $('#tendersPerPage');

    $category.on('change', function () {
      loadCategoryFilterFields($(this));
    });

    $perPageSelect.on('change', function () {
      $perPageField.val($perPageSelect.val());
    });

    $(document).on('change', '.parent-filter-choice :checkbox', function () {
      var $this = $(this),
        $parent = $this.parents('.parent-filter-choice');

      if ($parent.data('filter-children')) {
        if ($this.is(':checked')) {
          loadCategoryFilterDoubleChoiceChildrenFields($parent, $this);
        } else {
          $parent.next('.child-options').empty();
        }
      }
    });
  }


  function loadCategoryFields() {
    var $this = $(this),
      $categoryFields = $('#categoryFields'),
      $currency = $('.currency');

    var currentlySelectedCategory = parseInt($this.val());
    var categoriesWithAttachments = $('#category_id').data('cats-with-attachments');

    $attachmentsBox = $('#attachmentsBox');

    if (!$attachmentsBox.hasClass('hidden')) {
      $attachmentsBox.addClass('hidden');
    }

    if (categoriesWithAttachments.includes(currentlySelectedCategory)) {
      $attachmentsBox.removeClass('hidden');
    }

    $.ajax({
      type: 'GET',
      url: $this.data('url').replace(ID_REPLACER, $this.val()),
      beforeSend: function () {
        $categoryFields.find('.form-group, .positions, .positions-hr').remove();
        $categoryFields.addClass('ajax-box-loading');
      },
      success: function (response) {
        $categoryFields.removeClass('ajax-box-loading');

        $categoryFields.append(response.fields);

        if (response.currency.select) {
          $currency.html(response.currency.select);
          $currency.find('select[data-plugin-selecttwo]').each(function () {
            var $this = $(this);
            $this.select2();
          });
        } else {
          $currency.html(response.currency.input);
        }

        var $select = $categoryFields.find('select[data-plugin-selecttwo]');
        $select.each(function () {
          var $this = $(this);
          $this.select2($this.data('plugin-options'));
        });

        var $datepicker = $categoryFields.find('[data-plugin-datepicker]');
        $datepicker.each(function () {
          var $this = $(this);
          $this.datepicker($this.data('plugin-options'));
        });
      },
      error: function (e, a, b) {
        $categoryFields.removeClass('ajax-box-loading');
        swal("Error!", null, "error");
      }
    });
  }


  function loadCategoryFilterFields($this) {
    $this = $this || $(this);
    var $filtersSection = $('#tenderFilters'),
      $loading = $filtersSection.prev('.ajax-box');

    $.ajax({
      type: 'GET',
      url: $filtersSection.data('url') + '/' + $this.val() + window.location.search,
      beforeSend: function () {
        $filtersSection.empty();
        $loading.addClass('ajax-box-loading');
      },
      success: function (response) {
        $filtersSection.html(response);

        // Reinit Select2 Plugin
        var $select = $filtersSection.find('select[data-plugin-selecttwo]');
        $select.each(function () {
          var $this = $(this);
          $this.select2($this.data('plugin-options'));
        });

        // Reinit Datepicker Plugin
        var $datepicker = $filtersSection.find('[data-plugin-datepicker]');
        $datepicker.each(function () {
          var $this = $(this);
          $this.datepicker($this.data('plugin-options'));
        });

        // Reinit Toggle Plugin
        var $toggleSection = $filtersSection.find('[data-plugin-toggle]');
        var togglePluginOptions = $toggleSection.data('plugin-options'),
          opts;
        if (togglePluginOptions)
          opts = togglePluginOptions;

        $toggleSection.themePluginToggle(opts);

      },
      complete: function () {
        $loading.removeClass('ajax-box-loading');
      },
      error: function (e, a, b) {
        //
        swal("Error!", null, "error");
      }
    });
  }


  function loadCategoryFilterDoubleChoiceChildrenFields($parent, $checkbox) {
    $.ajax({
      type: 'POST',
      url: $parent.data('url'),
      beforeSend: function () {
        //
      },
      success: function (response) {
        $parent.next('.child-options').html(response);
      },
      error: function (e, a, b) {
        swal("Error!", null, "error");
      }
    });
  }


  function handleMultiDropdown() {
    var $this = $(this),
      $container = $this.parents('.multi-select'),
      $childSelect = $container.find('.child-select');

    if ($this.val() != 0) {

      $.ajax({
        type: 'GET',
        url: $this.data('url').replace(ID_REPLACER, $this.val()),
        beforeSend: function () {
          $this.attr('disabled', 'disabled');
          $childSelect.attr('disabled', 'disabled');
        },
        success: function (response) {
          var $newChildSelect = $(response);

          $this.removeAttr('disabled');
          $childSelect.eq($childSelect.length - 1).after($newChildSelect);
          $childSelect.remove();
          $newChildSelect.select2($newChildSelect.data('plugin-options'));
        },
        error: function (e, a, b) {
          $this.removeAttr('disabled');
          $childSelect.removeAttr('disabled');
          swal("Error!", null, "error");
        }
      });
    }
  }


  function handleTenderPositions(e) {
    var $this = $(this);
    var $positions = $('.positions');

    if ($this.prop('checked')) {
      $.ajax({
        type: 'GET',
        url: $this.data('url'),
        beforeSend: function () {
          $this.attr('disabled', 'disabled');
        },
        success: function (response) {
          $positions.html(response);
          $positions.find('select').each(function () {
            $(this).select2($(this).data('plugin-options'));
          });
          $this.removeAttr('disabled');
        },
        error: function (e, a, b) {
          $this.removeAttr('disabled');
          swal("Error!", null, "error");
        }
      });
    } else {
      $positions.empty();
    }
  }


  function handleBids() {
    var $submitBid = $('#submitBid');
    var oldValue = {};
    var btnText = '';
    var changed = false;
    var $th;
    var spanHtml;

    $(document).on('input', '.bid-amount', function (e) {
      var $this = $(this);
      var value = $this.val().trim();
      $this.parent().removeClass('has-error');

      if (/^\d*$/.test(value)) {
        oldValue[$this.data('i')] = value;
        $this.val(value);
      } else {
        $this.val(oldValue[$this.data('i')]);
      }

      var $btn = $this.next().find('button');
      var $span = $this.parents('table').find('.bid-amount-label');
      $th = $th || $span.parents('th');
      spanHtml = spanHtml || $th.html();

      if ($btn.length) {
        if ($btn.hasClass('disabled') || value == $this.data('old-value')) {
          if (value != $this.data('old-value')) {
            btnText = $btn.text();
            $btn.removeClass('disabled btn-success').addClass('btn-primary update-bid').text($btn.data('change-bid-text'));
          } else {
            $btn.removeClass('btn-primary update-bid').addClass('disabled btn-success').text(btnText);
          }
        }
      } else if ($span.length || changed) {
        var oldAmount = 0;
        var newAmount = 0;
        var $tpl = $('#template-bid-list');

        $this.parents('table').find('.bid-amount').each(function () {
          oldAmount += parseInt($.trim($(this).data('old-value')));
          newAmount += parseInt($.trim($(this).val()));
        });

        if (oldAmount != newAmount) {
          $th.html($tpl.html());
          changed = true;
        } else {
          console.log($th);
          console.log(spanHtml);
          $th.html(spanHtml);
        }
      }

    });

    // SUBMIT BID
    $submitBid.on('click', function (e) {
      e.preventDefault();

      var $this = $(this);
      var $bids = $this.parents('.has-bid').find('.bid-amount');
      var bids = [];
      var valid = true;

      $bids.each(function () {
        var $field = $(this);
        var value = parseInt($field.val().trim());

        if (isNaN(value)) {
          valid = false;
          $field.parent().addClass('has-error');
        }

        if ($field.data('position')) {
          bids[+$field.data('position')] = value;
        } else {
          bids.push(value);
        }
      });

      if (valid) {
        $.ajax({
          type: 'POST',
          url: $this.data('url'),
          data: {
            bids: bids
          },
          beforeSend: function () {
            $this.attr('disabled', 'disabled');
          },
          success: function (response) {
            $this.removeAttr('disabled');
            if (response.success) {
              swal({
                title: MESSAGES.successText,
                text: MESSAGES.bidCreated,
                type: 'success'
              }, function (isConfirm) {
                window.location.reload();
              });
            } else {
              swal({
                title: MESSAGES.errorText,
                text: response.error,
                type: 'error'
              });
            }
          },
          error: function (e, a, b) {
            $this.removeAttr('disabled');
            swal(MESSAGES.errorText, MESSAGES.somethingWentWrongText, "error");
          }
        });
      }
    });

    // SUBMIT BID
    $(document).on('click', '.update-bid', function (e) {
      e.preventDefault();

      var $this = $(this);
      var $bids = $this.parents('.has-bid').find('.bid-amount');
      var bids = [];
      var valid = true;

      $bids.each(function () {
        var $field = $(this);
        var value = parseInt($field.val().trim());

        if (isNaN(value)) {
          valid = false;
          $field.parent().addClass('has-error');
        }

        if ($field.data('position')) {
          bids[+$field.data('position')] = value;
        } else {
          bids.push(value);
        }
      });

      if (valid) {
        $.ajax({
          type: 'PUT',
          url: $this.data('url'),
          data: {
            bids: bids
          },
          beforeSend: function () {
            $this.attr('disabled', 'disabled');
          },
          success: function (response) {
            $this.removeAttr('disabled');
            if (response.success) {
              swal({
                title: MESSAGES.successText,
                text: MESSAGES.bidUpdated,
                type: 'success'
              }, function (isConfirm) {
                window.location.reload();
              });
            } else {
              swal({
                title: MESSAGES.errorText,
                text: response.error,
                type: 'error'
              });
            }
          },
          error: function (e, a, b) {
            $this.removeAttr('disabled');
            swal(MESSAGES.errorText, MESSAGES.somethingWentWrongText, "error");
          }
        });
      }
    });

    // DELETE BID
    $(document).on('click', '.delete-bid', function () {
      var $this = $(this);
      var url = $this.data('delete-url');
      swal({
        title: MESSAGES.warningText,
        text: MESSAGES.deleteBidSure,
        type: "error",
        confirmButtonText: MESSAGES.yesText,
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      }, function () {
        $.ajax({
          type: 'DELETE',
          url: url,
          beforeSend: function () {
            $this.attr('disabled', 'disabled');
          },
          success: function (response) {
            $this.removeAttr('disabled');

            if (response.success) {
              $this.parents('tr').remove();

              swal({
                title: MESSAGES.successText,
                text: MESSAGES.bidDeleted,
                type: 'success'
              }, function () {
                if ($this.data('reload')) window.location.reload();
              });
            } else {
              swal({
                title: MESSAGES.errorText,
                text: response.error,
                type: 'error'
              });
            }
          },
          error: function (e, a, b) {
            $this.removeAttr('disabled');
            swal(MESSAGES.errorText, MESSAGES.somethingWentWrongText, "error");
          }
        });
      });
    });


    // ACCEPT BID
    $(document).on('click', '.accept-bid', function () {
      var $this = $(this);
      var url = $this.data('bid-url');
      swal({
        title: MESSAGES.warningText,
        text: MESSAGES.acceptBidSure,
        type: "warning",
        confirmButtonText: MESSAGES.yesText,
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true
      }, function () {
        $.ajax({
          type: 'POST',
          url: url,
          beforeSend: function () {
            $this.attr('disabled', 'disabled');
          },
          success: function (response) {
            $this.removeAttr('disabled');

            if (response.success) {
              $this.parents('tr').addClass('success bid-accepted');
              $this.replaceWith('<p style="color: #fff;"><i class="fa fa-check"></i></p>');
              $('.accept-bid').remove();

              swal({
                title: MESSAGES.successText,
                text: MESSAGES.bidAccepted,
                type: 'success'
              });
            } else {
              swal({
                title: MESSAGES.errorText,
                text: response.error,
                type: 'error'
              });
            }
          },
          error: function (e, a, b) {
            $this.removeAttr('disabled');
            swal(MESSAGES.errorText, MESSAGES.somethingWentWrongText, "error");
          }
        });
      });
    });
  }

  /**
   * Data tables
   */
  var DATA_TABLE_OPTIONS = {
    columnDefs: [{
      targets: 'no-sort',
      orderable: false,
      order: []
    }]
  };

  var deleteRow = function (e) {
    e.preventDefault();

    var $row = $(this).closest('tr');

    console.log($row);

    $.magnificPopup.open({
      items: {
        src: dialog.$window,
        type: 'inline'
      },
      preloader: false,
      modal: true,
      callbacks: {
        change: function () {
          dialog.$confirmButton.on('click', function (e) {
            e.preventDefault();

            dialog.rowRemove($row);
            $.magnificPopup.close();
          });
        },
        close: function () {
          dialog.$confirmButton.off('click');
        }
      }
    });

    dialog.$cancelButton.on('click', function (e) {
      e.preventDefault();
      $.magnificPopup.close();
    });

  };

  var $dataTables = $('.datatable-default');
  $dataTables.dataTable(DATA_TABLE_OPTIONS);
  $dataTables.on('click', 'a.remove-row', deleteRow);

  $('select').select2();


  /**
   * EDITOR
   */
  function initEditor() {
    jQuery.event.props.push('dataTransfer');

    var $editorContainer = $('.ajax-image-editor'),
      availableFileTypes = $editorContainer.data('available-types').split(','),
      uploadUrl = $editorContainer.data('upload-url'),
      saveUrl = $editorContainer.data('save-url'),
      $loading = $editorContainer.find('.loading'),
      $chooseImageText = $editorContainer.find('h5'),
      $chooseImageTextBuff,
      $ajaxBox = $editorContainer.find('.ajax-box'),
      $editArea = $editorContainer.find('.edit-area'),
      $carousel = $editorContainer.find('.owl-carousel'),
      $addTextModal = $('#addTextModal'),
      $addTextModalFormSubmit = $addTextModal.find('.submit'),
      lastImageIndex = 0,
      edited = false,
      editingText = false,
      editTextIndex,
      paintOver = false,
      paintOverColor = configs.editor_paint_over_color ? configs.editor_paint_over_color : '#ffffff',
      polygons = false,
      paintOverPointsCount = 0,
      maxImages = configs.editor_max_images ? configs.editor_max_images : 100,
      imagesCount = 0,

      $uploadedImages = $('#uploaded-images'),

      buttons = {
        $paintOver: $editorContainer.find('.paint-over'),
        $addText: $editorContainer.find('.add-text'),
        $save: $editorContainer.find('.save'),
        $remove: $editorContainer.find('.remove'),
        $clear: $editorContainer.find('.clear'),
      },

      items = [],
      currentItem = 0,

      clearEditArea = function () {
        $ajaxBox.addClass('ajax-box-loading');
        if (!$chooseImageTextBuff) $chooseImageTextBuff = $chooseImageText.clone();
        $chooseImageText.remove();
        $editArea.empty();
      },

      showSaveButton = function () {
        if (buttons.$save.hasClass('hide')) {
          buttons.$save.find('span').text(MESSAGES.saveText);
          buttons.$save.removeClass('hide');

          buttons.$clear.removeClass('hide');

          items[currentItem].$image.parents('.owl-item').removeClass('saved')
            .find('.fa-check-circle').addClass('hide');
        }
      },

      showRemoveButton = function () {
        if (buttons.$remove.hasClass('hide')) {
          buttons.$remove.removeClass('hide');
        }
      },

      createThumb = function (data) {
        return '<div>' +
          '<img class="img-responsive img-rounded" data-index="' + (lastImageIndex) + '" src="' + data.imageThumbSrc + '" data-full-src="' + data.imageSrc + '" data-image-data=' + '\'' + JSON.stringify(data) + '\'' + '">' +
          '<i class="fa fa-check-circle hide"></i>' +
          '</div>';
      },

      createEditorImage = function (src) {
        return '<img class="editor-image" src="' + src + '">';
      },

      askForEditorText = function (edit) {
        var $fontSizeField = $('#editor-font-size-field');
        if (edit) {
          var color = items[currentItem].texts[editTextIndex].color;
          $('#editor-text-field').val(items[currentItem].texts[editTextIndex].text);
          $('#editor-color-field').val(color).parent().find('i').css('background', color);
          $fontSizeField.val(items[currentItem].texts[editTextIndex].fontSize);
          $fontSizeField.trigger('change');
        } else {
          color = '#000000';
          $('#editor-color-field').val(color).parent().find('i').css('background', color);
          $fontSizeField.val(16);
          $fontSizeField.trigger('change');
        }
        $addTextModal.modal('show');
      },
      createEditorText = function (text) {
        return '<p class="editor-text" data-index="' + (items[currentItem].texts.length - 1) + '" ' +
          'style="font-size: ' + text.fontSize + 'px; line-height: ' + text.fontSize + 'px; color: ' + text.color + '; top: ' + text.top + 'px; left: ' + text.left + 'px;"' +
          ' title="' + MESSAGES.doubleClickToEditText + '">' + text.text + '</p>';
      },

      editText = function (index) {
        editingText = true;
        editTextIndex = index;
        askForEditorText(true);
      },

      deleteText = function (index) {
        swal({
          title: MESSAGES.areYouSureText,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: MESSAGES.yesText + ', ' + MESSAGES.deleteText + '!'
        }, function () {
          var oldTexts = items[currentItem].texts;
          items[currentItem].texts = [];
          oldTexts.forEach(function (text, i) {
            if (i != index) items[currentItem].texts.push(text);
          });
          $editArea.find('.editor-text[data-index=' + index + ']').remove();
        });
      },

      createPolygonsWrapper = function () {
        polygons = true;
        $editArea.append('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ' + ($editArea.width() - 2) + ' ' + ($editArea.height() - 2) + '" class="editor-polygons"></svg>');
        $(window).on('resize', function () {
          $editArea.find('svg')[0].setAttribute('viewBox', '0 0 ' + ($editArea.width() - 2) + ' ' + ($editArea.height() - 2));
        });
      },

      createPoint = function (top, left) {
        return '<span class="editor-point" style="top: ' + (top - 3) + 'px; left: ' + (left - 3) + 'px; background: ' + paintOverColor + ';"></span>';
      },

      cancelPaintOver = function () {
        paintOver = false;
        paintOverPointsCount = 0;
        $editArea.removeClass('paint-over');
        buttons.$paintOver.find('span').text(MESSAGES.paintOverText);
        buttons.$paintOver.find('i').removeClass('fa-times').addClass('fa-paint-brush');
        $editArea.find('.editor-point').remove();
        buttons.$addText.removeAttr('disabled');
        buttons.$save.removeAttr('disabled');
      },

      drawPolygon = function () {
        cancelPaintOver();

        var polygonIndex = items[currentItem].polygons.length - 1,
          polygons = $editArea.find('.editor-polygons')[0],
          polygon = document.createElementNS("http://www.w3.org/2000/svg", 'polygon'),
          points = '';

        items[currentItem].polygons[polygonIndex].points.forEach(function (point) {
          points += point.x + ',' + point.y + ' ';
        });

        polygon.setAttribute("points", $.trim(points));
        polygon.setAttribute("style", "fill: " + paintOverColor);
        polygon.setAttribute("data-index", polygonIndex);
        polygons.appendChild(polygon);
        $(polygon).contextMenu({
          menuSelector: "#contextMenuPolygon",
          menuSelected: function ($invokedOn, $selectedMenu) {
            deletePolygon($(polygon));
          }
        });

        showSaveButton();
      },

      deletePolygon = function ($polygon) {
        swal({
          title: MESSAGES.areYouSureText,
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: MESSAGES.yesText + ', ' + MESSAGES.deleteText + '!'
        }, function () {
          var oldPolygons = items[currentItem].polygons;
          items[currentItem].polygons = [];
          oldPolygons.forEach(function (polygon, i) {
            if (i != $polygon.data('index')) items[currentItem].polygons.push(polygon);
          });
          $polygon.remove();
        });
      },


      loadImageToEditArea = function ($img, reinit) {
        var image = new Image;
        var src = $img.data('fullSrc');
        var imageData = $img.data('imageData');
        var reinit = reinit || false;


        image.src = src;
		
		image.onload = function(){
			$editArea.css({maxHeight: this.height + 2, maxWidth: this.width + 2});
		}
		
        currentItem = $img.data('index');

        clearEditArea();

														   
													 
		 
															 
																	  
		 

        $editArea.addClass('edited');

        $editArea.append(createEditorImage(src));

        items[currentItem] = {
          index: currentItem,
          image: image,
          $image: $img,
          imageData: imageData,
          texts: [],
          polygons: []
        };
        editingText = false;
        paintOver = false;
        polygons = false;
        paintOverPointsCount = 0;

        buttons.$save.addClass('hide').removeAttr('disabled');
        buttons.$clear.addClass('hide');

        $ajaxBox.removeClass('ajax-box-loading');

        edited = true;

        showRemoveButton();
      },

      updateUploadedImages = function () {
        var images = [];

        $carousel.find('img').each(function () {
          images.push($(this).data('image-data'));
        });

        $uploadedImages.val(JSON.stringify(images));
      };

    $editorContainer.on('click', 'button', function (e) {
      e.preventDefault();
      return false;
    });

    $addTextModalFormSubmit.on('click', function () {
      var text = $('#editor-text-field').val(),
        color = $('#editor-color-field').val(),
        fontSize = parseInt($('#editor-font-size-field').val());

      if ($.trim(text) === '') {
        swal(MESSAGES.errorText, MESSAGES.youNeedToWriteTextText, 'error');
        return false;
      }

      if (!editingText) {
        items[currentItem].texts.push({
          text: text,
          color: color,
          fontSize: fontSize,
          top: 0,
          left: 0
        });

        var textIndex = items[currentItem].texts.length - 1;

        $editArea.append(createEditorText(items[currentItem].texts[textIndex]));
        $editArea.find('.editor-text[data-index=' + textIndex + ']').draggable({
          containment: 'parent',
          drag: function () {
            var $this = $(this);
            items[currentItem].texts[textIndex].top = parseInt($this.css('top'));
            items[currentItem].texts[textIndex].left = parseInt($this.css('left'));
          }
        }).contextMenu({
          menuSelector: "#contextMenu",
          menuSelected: function ($invokedOn, $selectedMenu) {
            var action = $selectedMenu.data('action');

            if (action === 'edit') {
              editText($invokedOn.data('index'));
            }
            if (action === 'delete') {
              deleteText($invokedOn.data('index'));
            }
          }
        });
      } else {
        items[currentItem].texts[editTextIndex].text = text;
        items[currentItem].texts[editTextIndex].color = color;
        items[currentItem].texts[editTextIndex].fontSize = fontSize;
        $editArea.find('.editor-text[data-index=' + editTextIndex + ']')
          .css(items[currentItem].texts[editTextIndex])
          .text(text);
        editingText = false;
      }

      $addTextModal.modal('hide');

      showSaveButton();
    });

    $carousel.on('click', 'img', function (e) {
      loadImageToEditArea($(this));
    });


    // Handle file uploading
    $editorContainer.on('change', 'input[type=file]', function () {
      var $fileInput = $(this),
        files = this.files;

      if ((files.length + imagesCount) >= maxImages) {
        var filesLength = maxImages - imagesCount;
        swal(MESSAGES.warningText, MESSAGES.imageLimitExceededText.replace(':count', maxImages), "warning")
      } else {
        filesLength = files.length;
      }

      for (var i = 0; i < filesLength; i++) {
        if (imagesCount < maxImages) {
          var file = files[i],
            fileType = file.type.split('/')[1];

          if ($.inArray(fileType, availableFileTypes) > -1) {
            var formData = new FormData;
            formData.append('image', file);

            $.ajax({
              url: uploadUrl,
              type: 'POST',
              processData: false,
              contentType: false,
              cache: false,
              data: formData,
              dataType: 'JSON',
              beforeSend: function () {
                $fileInput.attr('disabled', 'disabled');
                $loading.removeClass('hide');
              },
              complete: function () {
                $editorContainer.find('input[type=file]').removeAttr('disabled');
                $loading.addClass('hide');
              },
              success: function (response) {
                console.log(response);

                $carousel.trigger('add.owl.carousel', createThumb(response)).trigger('refresh.owl.carousel');
                updateUploadedImages();
                lastImageIndex++;
                imagesCount++;
              },
              error: function (xhr) {
                swal(MESSAGES.errorText, xhr.responseJSON.error, 'error');
              }
            });
          }
        } else {
          swal(MESSAGES.warningText, MESSAGES.imageLimitExceededText.replace(':count', maxImages), "warning")
        }
      }

      $fileInput.replaceWith($fileInput.clone(true));
    });


    // Handle text adding
    buttons.$addText.on('click', function (e) {
      if (edited) {
        askForEditorText();
      } else {
        swal(MESSAGES.selectImageFirstText, null, "error")
      }
    });

    // Handle text editing
    $editorContainer.on('dblclick', '.editor-text', function (e) {
      editText(parseInt($(this).data('index')));
    });

    // Handle text editing
    $addTextModal.on('hide.bs.modal', function () {
      editingText = false;
    });


    // Handle polygons adding
    buttons.$paintOver.on('click', function (e) {
      if (edited) {
        if (paintOver) {
          cancelPaintOver();
        } else {
          paintOver = true;
          $editArea.addClass('paint-over');

          buttons.$addText.attr('disabled', 'disabled');
          buttons.$save.attr('disabled', 'disabled');
          buttons.$paintOver.find('span').text(MESSAGES.cancelButtonText);
          buttons.$paintOver.find('i').removeClass('fa-paint-brush').addClass('fa-times');
        }

      } else {
        swal(MESSAGES.selectImageFirstText, null, "error")
      }
    });


    // Handle polygons adding
    $editArea.on('click', function (e) {
      if (paintOver) {
        var offset = $(this).offset(),
          left = e.pageX - offset.left,
          top = e.pageY - offset.top;

        $editArea.append(createPoint(top, left));

        paintOverPointsCount++;

        if (paintOverPointsCount == 1) {
          items[currentItem].polygons[items[currentItem].polygons.length] = {};
          items[currentItem].polygons[items[currentItem].polygons.length - 1].points = [];
        }

        items[currentItem].polygons[items[currentItem].polygons.length - 1].points.push({
          x: left,
          y: top
        });


        if (paintOverPointsCount == 4) {
          if (!polygons) {
            createPolygonsWrapper();
          }
          drawPolygon();
        }
      }
    });


    // Handle saving
    buttons.$save.on('click', function (e) {
      swal({
        title: MESSAGES.saveText + '?',
        text: MESSAGES.willMergedText,
        type: "info",
        showCancelButton: true,
        closeOnConfirm: false,
        showLoaderOnConfirm: true,
      }, function () {
        $.ajax({
          url: saveUrl,
          type: 'POST',
          data: {
            image: {
              id: items[currentItem].imageData.id,
              name: items[currentItem].imageData.name,
              original_name: items[currentItem].imageData.original_name,
              server_path: items[currentItem].imageData.server_path,
            },
            texts: items[currentItem].texts,
            polygons: items[currentItem].polygons,
          },
          success: function (response) {
            items[currentItem].$image.parents('.owl-item').addClass('saved')
              .find('.fa-check-circle').removeClass('hide');

            buttons.$save.attr('disabled', 'disabled').find('span').text(MESSAGES.savedText);

            items[currentItem].$image.attr('src', response.image.imageThumbSrc)
              .data('src', response.image.imageThumbSrc)
              .data('full-src', response.image.imageSrc)
              .data('image-data', response.image);

            loadImageToEditArea(items[currentItem].$image);

            updateUploadedImages();

            swal(MESSAGES.savedText + '!');
          },
          error: function (xhr) {
            swal(MESSAGES.errorText, xhr.responseJSON.error, 'error');
          }
        });
      });
    });


    // Handle removing
    buttons.$remove.on('click', function (e) {
      swal({
        title: MESSAGES.areYouSureText,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: MESSAGES.yesText + ', ' + MESSAGES.deleteText + '!'
      }, function () {
        var oldItems = items;
        items = [];

        oldItems.forEach(function (item) {
          if (item.index == currentItem) {
            $.ajax({
              url: item.imageData.deleteUrl,
              type: 'delete'
            });
          } else {
            items[item.index] = item;
          }
        });

        var position = $carousel.find('img[data-index=' + currentItem + ']').parents('.owl-item').index();

        $carousel.trigger('remove.owl.carousel', position).trigger('refresh.owl.carousel');

        $editArea.empty().append($chooseImageTextBuff).removeClass('edited');

        updateUploadedImages();

        imagesCount--;
      });
    });


    buttons.$clear.on('click', function () {
      swal({
        title: MESSAGES.areYouSureText,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: MESSAGES.yesText + ', ' + MESSAGES.clearText + '!'
      }, function () {
        loadImageToEditArea(items[currentItem].$image);
      });
    });

  }

  function handleDeletes() {
    $(document).on('click', '.delete', function (e) {
      e.preventDefault();

      var $this = $(this);
      var $deleteModal = $('#deleteModal');
      var $deleteForm = $('#deleteForm');

      $deleteForm.attr('action', $this.data('delete-url'));

      if ($this.data('delete-instance')) {
        $deleteModal.find('.instance').text($this.data('delete-instance'));
      }

      $deleteModal.modal('show');
    });
  }


  getConfigs().done(function () {
    if ($('#editor').length) {
      initEditor();
    }
  });

  initDropzone();

  handleCategoryFields();

  handleTenderFilters();

  handleBids();

  handleDeletes();

})(jQuery);