function log(v){
    console.log(v);
}


function URLToArray(url) {
    var request = {};
    var arr = [];
    var pairs = url.substring(url.indexOf('?') + 1).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');

        //check we have an array here - add array numeric indexes so the key elem[] is not identical.
        if(endsWith(decodeURIComponent(pair[0]), '[]') ) {
            var arrName = decodeURIComponent(pair[0]).substring(0, decodeURIComponent(pair[0]).length - 2);
            if(!(arrName in arr)) {
                arr.push(arrName);
                arr[arrName] = [];
            }

            arr[arrName].push(decodeURIComponent(pair[1]));
            request[arrName] = arr[arrName];
        } else {
            request[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
        }
    }
    return request;
}
function endsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
}


var build_query = function (obj, num_prefix, temp_key) {

    var output_string = [];

    Object.keys(obj).forEach(function (val) {

        var key = val;

        num_prefix && !isNaN(key) ? key = num_prefix + key : '';

        key = encodeURIComponent(key.replace(/[!'()*]/g, escape));
        temp_key ? key = temp_key + '[' + key + ']' : '';

        if (typeof obj[val] === 'object') {
            var query = build_query(obj[val], null, key);
            output_string.push(query)
        }

        else {
            var value = encodeURIComponent(obj[val].replace(/[!'()*]/g, escape));
            output_string.push(key + '=' + value)
        }

    });

    return output_string.join('&');

};